import { useCallback, useMemo, useState } from 'react'
import { UseTRPCInfiniteQueryOptions } from '@trpc/react-query/shared'
import { useQueryClient, InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query'
import { nextTrpc } from './trpc'
import { inferRouterOutputs, inferRouterInputs } from '@trpc/server'
import { TRPCClientErrorLike } from '@trpc/client'
import type { AppRouter } from '@/src/server/routers/_app' // Update this import path
import { GenreListItem, Sample } from '../types'
import { useLooper } from '../context/looper-provider'

interface GenreListPage {
  items: GenreListItem[]
  nextCursor: string | undefined
}

type GenreListInfiniteQueryResult = UseInfiniteQueryResult<GenreListPage, unknown>
type RouterOutput = inferRouterOutputs<AppRouter>
type RouterInput = inferRouterInputs<AppRouter>

export const useGenreList = (view: 'genres' | 'categories' | 'samples') => {
  const queryClient = useQueryClient()
  const { key, keyLocked } = useLooper()
  const [isSearching, setIsSearching] = useState(false)
  const [searchResults, setSearchResults] = useState<GenreListItem[]>([])

  const query: GenreListInfiniteQueryResult = nextTrpc.loops.getGenreList.useInfiniteQuery(
    {
      limit: 20,
      key: keyLocked ? key : undefined
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      enabled: view === 'genres',
      initialPageParam: undefined
    } as UseTRPCInfiniteQueryOptions<
      'loops.getGenreList',
      RouterInput['loops']['getGenreList'],
      RouterOutput['loops']['getGenreList'],
      TRPCClientErrorLike<AppRouter>
    >
  )

  const {
    data: queryGenreListData,
    fetchNextPage: fetchNextGenrePage,
    hasNextPage: hasGenreNextPage,
    isFetchingNextPage: isFetchingNextGenrePage,
    refetch: refetchGenres,
    isLoading: isInitialLoading
  } = query

  const genreListData = useMemo(() => {
    if (isSearching) {
      return {
        pages: [{ items: searchResults, nextCursor: undefined }],
        pageParams: [null]
      } as InfiniteData<GenreListPage>
    }
    return queryGenreListData
  }, [isSearching, searchResults, queryGenreListData])

  const updateGenreListData = useCallback(
    (newData: GenreListItem[]) => {
      setSearchResults(newData)
      setIsSearching(true)
      queryClient.setQueryData<InfiniteData<GenreListPage>>(['loops', 'getGenreList'], {
        pages: [{ items: newData, nextCursor: undefined }],
        pageParams: [null]
      })
    },
    [queryClient]
  )

  const loadMoreGenres = useCallback(() => {
    if (hasGenreNextPage && !isFetchingNextGenrePage) {
      fetchNextGenrePage()
    }
  }, [hasGenreNextPage, isFetchingNextGenrePage, fetchNextGenrePage])

  const clearSearch = useCallback(() => {
    setIsSearching(false)
    refetchGenres()
  }, [refetchGenres])

  return {
    genreListData,
    fetchNextGenrePage,
    hasGenreNextPage,
    isFetchingNextGenrePage,
    refetchGenres,
    loadMoreGenres,
    updateGenreListData,
    clearSearch,
    isSearching,
    setIsSearching,
    isInitialLoading
  }
}

interface SamplePage {
  items: Sample[]
  nextCursor: string | undefined
}

type SampleInfiniteQueryResult = UseInfiniteQueryResult<SamplePage, unknown>

export const useSampleList = (
  selectedGenre: GenreListItem | null,
  selectedCategory: 'drums' | 'songstarter' | null,
  view: 'genres' | 'categories' | 'samples'
) => {
  const queryClient = useQueryClient()
  const { key, keyLocked } = useLooper()
  const [isSearching, setIsSearching] = useState(false)
  const [searchResults, setSearchResults] = useState<Sample[]>([])

  const query: SampleInfiniteQueryResult = nextTrpc.loops.getSamples.useInfiniteQuery(
    {
      key: keyLocked ? key : undefined,
      genre: selectedGenre?.name ?? '',
      instrument: selectedCategory ?? undefined,
      limit: 20
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor ?? undefined,
      enabled: !!selectedGenre && !!selectedCategory && view === 'samples',
      initialPageParam: undefined
    } as UseTRPCInfiniteQueryOptions<
      'loops.getSamples',
      RouterInput['loops']['getSamples'],
      RouterOutput['loops']['getSamples'],
      TRPCClientErrorLike<AppRouter>
    >
  )

  const {
    data: querySampleListData,
    fetchNextPage: fetchNextSamplePage,
    hasNextPage: hasSampleNextPage,
    isFetchingNextPage: isFetchingNextSamplePage,
    refetch: refetchSamples,
    isLoading: isInitialLoading
  } = query

  const sampleListData = useMemo(() => {
    if (isSearching) {
      return {
        pages: [{ items: searchResults, nextCursor: undefined }],
        pageParams: [null]
      } as InfiniteData<SamplePage>
    }
    return querySampleListData
  }, [isSearching, searchResults, querySampleListData])

  const updateSampleListData = useCallback(
    (newData: Sample[]) => {
      setSearchResults(newData)
      setIsSearching(true)
      queryClient.setQueryData<InfiniteData<SamplePage>>(['loops', 'getGenreList'], {
        pages: [{ items: newData, nextCursor: undefined }],
        pageParams: [null]
      })
    },
    [queryClient]
  )

  const loadMoreSamples = useCallback(() => {
    if (hasSampleNextPage && !isFetchingNextSamplePage) {
      fetchNextSamplePage()
    }
  }, [hasSampleNextPage, isFetchingNextSamplePage, fetchNextSamplePage])

  const clearSearch = useCallback(() => {
    setIsSearching(false)
    refetchSamples()
  }, [refetchSamples])

  return {
    sampleListData,
    fetchNextSamplePage,
    hasSampleNextPage,
    isFetchingNextSamplePage,
    refetchSamples,
    loadMoreSamples,
    updateSampleListData,
    clearSearch,
    isSearching,
    setIsSearching,
    isInitialLoading
  }
}

type FilterableItem = GenreListItem | Sample

export function useFilteredData<T extends FilterableItem>(
  data: InfiniteData<{ items: T[]; nextCursor: string | undefined }> | undefined,
  searchTerm: string
): T[] {
  const filterItems = useCallback(
    (items: T[]) =>
      items.filter((item) => {
        const searchField = 'title' in item ? item.title : item.name
        return searchField.toLowerCase().includes(searchTerm.toLowerCase())
      }),
    [searchTerm]
  )

  const filteredData = useMemo(() => {
    if (!data) return []

    const allItems = data.pages.flatMap((page) => page.items)

    // Remove duplicates based on item identifier (name for genres, id for samples)
    const uniqueItems = Array.from(
      new Map(allItems.map((item) => ['name' in item ? item.name : item.id, item])).values()
    )

    return filterItems(uniqueItems)
  }, [data, filterItems])

  return filteredData
}

import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react'
import { HBox } from 'intuitive-flexbox'
import { EndpointIncompleteOutput } from 'runpod-sdk'
import { toast } from 'sonner'
import { LuPlus } from 'react-icons/lu'
import { BiSolidError } from 'react-icons/bi'
import { runpod } from '@/src/lib/runpod'
import { clientTrpc } from '@/src/lib/trpc'
import { useSkin } from '@/src/context/skin-provider'
import { useTracks } from '@/src/context/audio-provider'
import { useMixpanelContext } from '@/src/lib/mixpanel/useMixpanel'
import { useAnimationMachine } from '@/src/context/animation-machine-provider'
import { Sample, StemsResult, isEndpointCompletedOutput, isStemsResult } from '@/src/types'
import { cn } from '@/src/lib/utils'
import jobFinish from '@/src/util/jobFinish'
import uploadBase64 from '@/src/util/uploadBase64'
import { MAX_RUNPOD_BODY_SIZE } from '@/src/util/constants'
import { SPLIT_STEMS_RUNPOD_ID, VANILLA_MUSICGEN_ID } from '@/src/lib/endpoints'
import { PromptTemplate, SongData } from '@/src/types'
import Modal from './Modal'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@radix-ui/react-tabs'
import { Card, CardContent, CardDescription, CardFooter, CardHeader } from './Card'
import PromptTemplateButtons from './PromptTemplateButtons'
import { FiZap } from 'react-icons/fi'
import GenreSampleBrowser from './GenreSampleBrowser'
import FirstVisitModal from './FirstVisitModal'
import { usePlayerStore } from '../lib/playerStore'
import { QueuedTrack, useTrackQueue } from '../lib/trackQueueStore'
import { useSearchParams } from 'next/navigation'
import { useLooper } from '../context/looper-provider'

const AddButton = ({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: Dispatch<SetStateAction<boolean>> }) => {
  const { skin } = useSkin()
  const { addButtonRef } = useAnimationMachine()

  return (
    <div
      ref={addButtonRef}
      className={cn('flex h-[48px] w-[48px] items-center justify-center rounded-full bg-primary')}
      style={{
        backgroundColor: skin.bgColor,
        color: skin.textColor
      }}
      onClick={() => setIsOpen(!isOpen)}
    >
      <LuPlus className="rounded-full" size={24} />
    </div>
  )
}

interface PlusButtonProps {
  setIsLoading: Dispatch<SetStateAction<boolean>>
  isLoading: boolean
  setIsGenerating: Dispatch<SetStateAction<boolean>>
  setSlapTitle: Dispatch<SetStateAction<string | null>>
  slapTitle: string | null
  setProgress: Dispatch<SetStateAction<number>>
  setDownbeatTimes: Dispatch<SetStateAction<number[]>>
  setIsSplitting: Dispatch<SetStateAction<boolean>>
  slapId: MutableRefObject<string | null>
  setCurrentPrompt: Dispatch<SetStateAction<string>>
  currentPrompt: string
  addPresetLoop: (sample: Sample, isRemixTrack?: boolean, newBpmAfterTrackAdded?: number) => void
  waitForTrackToEnd: () => Promise<void>
}

export const PlusButton: React.FC<PlusButtonProps> = ({
  setIsLoading,
  isLoading,
  setIsGenerating,
  setSlapTitle,
  slapTitle,
  setProgress,
  setDownbeatTimes,
  setIsSplitting,
  slapId,
  setCurrentPrompt,
  currentPrompt,
  addPresetLoop,
  waitForTrackToEnd
}: PlusButtonProps) => {
  const mixpanel = useMixpanelContext()
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)

  const isPlaying = usePlayerStore((state) => state.isPlaying)

  const { bpm, currentBpm, setBpm, key, currentKey, setKey } = useLooper()
  const { skin } = useSkin()

  const searchParams = useSearchParams()
  const isEmbedded = searchParams.get('embedded') !== null

  const { addItem: addItemToTrackQueue } = useTrackQueue()

  const [currentPromptLabel, setCurrentPromptLabel] = useState<string>('House')
  const { tracks, addNewTrack, lockedDuration, setLockedDuration } = useTracks()

  const [isOpen, setIsOpen] = useState(false)
  const [activeTab, setActiveTab] = useState('samples')

  const openModalWithTab = (tab: 'samples' | 'generate') => {
    setIsOpen(true)
    setActiveTab(tab)

    if (tab === 'generate') {
      !!textareaRef.current && textareaRef.current.focus()
    }
  }

  async function fetchTitle(title: string) {
    if (!slapTitle) {
      const generatedTitle = await clientTrpc.name.generate.mutate({
        prompt: `${title} ${currentPromptLabel}`
      })
      setSlapTitle(generatedTitle)
    }
  }

  const callGenAPI = async () => {
    try {
      setIsLoading(true)
      setIsGenerating(true)
      if (!slapTitle) fetchTitle(currentPromptLabel)
      const promptKey = currentKey ? currentKey : key
      const promptBpm = currentBpm ? currentBpm : bpm
      const prompt = `${currentPrompt} bpm:${promptBpm} key:${promptKey}`
      let genJobID: string | undefined
      try {
        genJobID = await clientTrpc.musicGen.genLoop.mutate({
          prompt,
          duration: lockedDuration
        })
      } catch (err) {
        console.error(err)
        throw new Error('Runpod job did not start successfully')
      }

      if (!genJobID) throw new Error('Runpod job did not start successfully')

      const genEndpoint = runpod.endpoint(VANILLA_MUSICGEN_ID)
      const resp = await jobFinish({
        func: async () => genEndpoint?.status(genJobID),
        condition: (resp) => {
          if (resp?.status === 'FAILED') console.error('Error generating loop: ', resp)
          return resp?.status === 'COMPLETED' || resp?.status === 'FAILED'
        },
        retry: -Infinity
      })

      if (!isEndpointCompletedOutput(resp)) {
        console.error(resp)
        setProgress(100)
        throw new Error('Runpod job did not complete successfully')
      }
      const songData: SongData[] = resp.output.outputs

      // although it's possible that multiple tracks could have different downbeat times, all tracks need to share the same downtime time regardless for the app to function. Therefore, we just use the first track's downtime time for all tracks.
      const debug = songData[0]?.debug
      if (debug && 'downbeat_times' in debug) setDownbeatTimes(debug.downbeat_times)

      setProgress(100)
      if (!lockedDuration) {
        setLockedDuration(songData[0].duration)
      }
      setIsSplitting(true)

      let inputUrl = songData[0].audio
      const isBase64 = songData[0].audio.startsWith('data:')
      if (isBase64) {
        // size of string is 2 bytes per character
        const sizeInMB = (songData[0].audio.length * 2) / 1024 / 1024
        if (sizeInMB > MAX_RUNPOD_BODY_SIZE) {
          const uploadedUrl = await uploadBase64(inputUrl)
          if (!uploadedUrl) throw new Error('Error: base64 audio string is corrupted.')
          inputUrl = uploadedUrl
        }
      }

      let splitJobID: string | undefined
      try {
        splitJobID = await clientTrpc.stems.splitStems.mutate({
          audio: inputUrl,
          duration: songData[0].duration
        })
      } catch (err) {
        console.error(err)
        throw new Error('Runpod Stem Split job did not start successfully')
      }

      if (!splitJobID) throw new Error('Runpod Stem Split job did not start successfully')

      const stemsEndpoint = runpod.endpoint(SPLIT_STEMS_RUNPOD_ID)

      const result = await jobFinish<StemsResult | EndpointIncompleteOutput | undefined>({
        func: async () => stemsEndpoint?.status(splitJobID),
        condition: (statusResponse) => {
          if (!statusResponse) console.error('Failed to split stems. No response from server.')
          if (statusResponse?.status === 'FAILED') console.error('Failed to split stems. Response: ', statusResponse)
          return statusResponse?.status === 'COMPLETED' || statusResponse?.status === 'FAILED'
        }
      })

      if (!isStemsResult(result)) {
        throw new Error('Failed to split stems')
      }

      const stemUrls = result.output?.stems

      setIsSplitting(false)

      if (tracks.length && isPlaying) await waitForTrackToEnd()

      await Promise.all(
        Object.entries(stemUrls).map(async ([name, url]) => {
          const stemSongData = [
            {
              ...songData[0],
              audio: url
            }
          ]
          const durationInMilliseconds = lockedDuration ? lockedDuration * 1000 : undefined
          if (tracks.length && isPlaying) {
            const newTrackArgs: QueuedTrack = {
              songData: stemSongData,
              title: name,
              sourceBpm: songData[0].bpm,
              targetBpm: currentBpm || bpm
            }
            if (durationInMilliseconds) newTrackArgs.durationInMilliseconds = durationInMilliseconds

            addItemToTrackQueue(newTrackArgs)
          } else {
            const track = await addNewTrack(
              stemSongData,
              name,
              songData[0].bpm,
              currentBpm || bpm,
              durationInMilliseconds
            )
            mixpanel.track({
              eventName: 'AddNewTrack',
              data: {
                currentPrompt,
                duration: lockedDuration,
                bpm,
                currentBpm,
                key,
                title: slapTitle,
                skinId: skin.id,
                track: {
                  id: track?.id,
                  title: track?.title,
                  isSoloed: track?.isSoloed,
                  isMuted: track?.isMuted
                },
                trackLength: tracks.length,
                parentId: slapId.current
              }
            })
            setIsLoading(false)
            setIsGenerating(false)
          }
        })
      ).then(() => {
        if (tracks.length && isPlaying) {
          waitForTrackToEnd().then(() => {
            setIsLoading(false)
            setIsGenerating(false)
          })
        }
      })
    } catch (error: any) {
      console.error(error)
      setIsLoading(false)
      setIsGenerating(false)
      toast(
        <div className="flex w-full justify-between">
          <span>Something went wrong 😕</span>
          <BiSolidError className="text-red-500" />
        </div>
      )
    }
  }

  useEffect(() => {
    if (activeTab === 'generate') {
      setTimeout(() => {
        textareaRef.current && textareaRef.current.focus()
      }, 100)
    }
  }, [activeTab, textareaRef])

  return (
    <HBox center>
      <>
        <AddButton isOpen={isOpen} setIsOpen={setIsOpen} />
        {!isEmbedded && (
          <FirstVisitModal
            modalKey="welcome-modal"
            title="Welcome to Slaps!"
            subtitle="Experiment with loops and ai generated sounds"
          >
            <p>
              <a className="cursor-pointer text-blue-500" onClick={() => openModalWithTab('samples')}>
                Explore samples
              </a>{' '}
              <span>or</span>{' '}
              <a className="cursor-pointer text-blue-500" onClick={() => openModalWithTab('generate')}>
                prompt our loop generation AI
              </a>
              <p className="mt-4 text-xs">
                Disclaimer: This is a demo, do not distribute any music generated from Slaps
              </p>
            </p>
          </FirstVisitModal>
        )}
        <Modal
          setStatus={null}
          onClose={() => {
            setIsOpen(false)
          }}
          className={cn('flex flex-col p-0', {
            'h-screen': activeTab === 'samples',
            'mt-28': isEmbedded
          })}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          forceDesktop={true}
        >
          <Tabs
            value={activeTab}
            onValueChange={setActiveTab}
            style={{
              backgroundColor: skin.bgColor,
              color: skin.textColor
            }}
          >
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger className="border border-b-0 border-l-0 border-t-0 border-gray-300" value="samples">
                Samples
              </TabsTrigger>
              <TabsTrigger className="border-t-1 border-b-0 border-gray-300 p-4" value="generate">
                Generate
              </TabsTrigger>
            </TabsList>
            <TabsContent value="samples" className="outline-none">
              <hr className="border-t-1 border-b-0 border-gray-300" />
              <GenreSampleBrowser onAdd={addPresetLoop} />
            </TabsContent>
            <TabsContent value="generate">
              <Card
                style={{
                  backgroundColor: skin.bgColor,
                  color: skin.textColor,
                  borderColor: 'white',
                  border: 'none',
                  borderTop: '1px solid'
                }}
              >
                <CardHeader className="px-6 py-2">
                  <CardDescription className="flex flex-col space-y-1.5 p-3 text-center">
                    Generate sounds for your session.
                  </CardDescription>
                </CardHeader>
                <CardContent className="space-y-2 px-8">
                  <textarea
                    ref={textareaRef}
                    onChange={(e) => setCurrentPrompt(e.target.value)}
                    value={currentPrompt}
                    className="min-h-96 w-full rounded bg-transparent outline-primary-foreground"
                    style={{
                      outlineColor: skin.textColor
                    }}
                    placeholder="soulful house music minor 7th chords rhodes piano deep bassline crispy analog percussion"
                  />
                </CardContent>
                <CardFooter>
                  <div className="flex flex-col">
                    <PromptTemplateButtons
                      onClick={(pt: PromptTemplate) => {
                        setCurrentPromptLabel(pt.label)
                        setCurrentPrompt(pt.prompt)
                        // Don't update bpm if tracks have already been generated
                        if (!tracks.length) {
                          setBpm(pt.bpm)
                          setKey(pt.key)
                        }
                        !!textareaRef.current && textareaRef.current.focus()
                      }}
                    />
                    <button
                      className="mt-4 flex w-full items-center justify-center gap-2 rounded-full bg-white px-2 py-1 font-medium text-primary disabled:cursor-not-allowed disabled:opacity-30"
                      disabled={!currentPrompt || isLoading}
                      onClick={() => {
                        callGenAPI()
                        mixpanel.track({
                          eventName: 'PromptButtonClick',
                          data: {
                            currentPrompt,
                            duration: lockedDuration,
                            bpm,
                            currentBpm,
                            key,
                            currentKey,
                            title: slapTitle,
                            skinId: skin.id,
                            trackLength: tracks.length,
                            parentId: slapId.current
                          }
                        })
                        setIsOpen(false)
                      }}
                    >
                      <span>Generate</span>
                      <FiZap />
                    </button>
                  </div>
                </CardFooter>
              </Card>
            </TabsContent>
          </Tabs>
        </Modal>
      </>
    </HBox>
  )
}

'use client'

import { useState, useRef, useEffect } from 'react'
import { Howl } from 'howler'
import { toast } from 'sonner'
import { upload } from '@vercel/blob/client'
import copy from 'copy-to-clipboard'
import { FiCheckCircle, FiUpload, FiFilm } from 'react-icons/fi'
import { RiImageAddLine, RiMovieFill } from 'react-icons/ri'
import { MdDragIndicator } from 'react-icons/md'
import { saveAs } from 'file-saver'
import { v4 as uuidv4 } from 'uuid'
import { useSwipeable } from 'react-swipeable'
import { useSkin } from '@/src/context/skin-provider'

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription
} from '@/src/components/ui/dialog'
import { Button } from '@/src/components/ui/button'
import { Progress } from '@/src/components/ui/progress'
import { PlayIcon, PauseIcon } from './play-pause'

interface AudioPreviewDialogProps {
  isOpen: boolean
  onClose: () => void
  audioBlob: Blob | null
  title: string
}

type MediaOption = {
  id: string
  type: 'video' | 'image' | 'upload'
  title: string
  previewUrl?: string
  icon: React.ReactNode
  file?: File | Blob
  isCustomUpload?: boolean
}

// We'll create the default options in the component so we can use the current skin
const AudioPreviewDialog = ({ isOpen, onClose, audioBlob, title }: AudioPreviewDialogProps) => {
  // Import the skin context to access the current skin
  const { skin } = useSkin()
  const [isPlaying, setIsPlaying] = useState(false)
  const [progress, setProgress] = useState(0)
  const [isUploading, setIsUploading] = useState(false)
  const [isMerging, setIsMerging] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  // Create default media options using the current skin
  const defaultMediaOptions: MediaOption[] = [
    {
      id: 'skin-video',
      type: 'video',
      title: 'Skin Video',
      previewUrl: skin.video, // Use the skin's video URL
      icon: <RiMovieFill size={24} />
    },
    {
      id: 'skin-image',
      type: 'image',
      title: 'Skin Image',
      previewUrl: skin.image, // Use the skin's image URL
      icon: <FiFilm size={24} />
    },
    {
      id: 'upload',
      type: 'upload',
      title: 'Upload Media',
      icon: <RiImageAddLine size={24} />,
      isCustomUpload: true
    }
  ]

  const [mediaOptions, setMediaOptions] = useState<MediaOption[]>(defaultMediaOptions)
  const [mergedVideoUrl, setMergedVideoUrl] = useState<string | null>(null)
  // We'll store uploaded media URLs in an object keyed by mediaOption.id
  const [_, setUploadedMediaUrls] = useState<Record<string, string>>({})
  // Detect if we're running in an iOS WebView
  const [isInIOSWebView, setIsInIOSWebView] = useState(false)

  const playerRef = useRef<Howl | null>(null)
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const progressIntervalRef = useRef<NodeJS.Timeout | null>(null)
  // One ref per upload component
  const fileInputRefs = useRef<Record<string, HTMLInputElement | null>>({})

  // Helper to get/set the file input ref for a specific upload option
  const getFileInputRef = (id: string) => {
    if (!fileInputRefs.current[id]) {
      fileInputRefs.current[id] = null
    }
    return fileInputRefs.current[id]
  }

  const setFileInputRef = (id: string, ref: HTMLInputElement | null) => {
    fileInputRefs.current[id] = ref
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (activeIndex < mediaOptions.length - 1) {
        setActiveIndex(activeIndex + 1)
      }
    },
    onSwipedRight: () => {
      if (activeIndex > 0) {
        setActiveIndex(activeIndex - 1)
      }
    },
    trackMouse: true
  })

  // Create audio player when blob is available
  useEffect(() => {
    if (!audioBlob) return

    // Convert blob to URL for Howler
    const audioUrl = URL.createObjectURL(audioBlob)

    playerRef.current = new Howl({
      src: [audioUrl],
      format: ['wav'],
      html5: true,
      onend: () => {
        setIsPlaying(false)
        setProgress(0)
        clearProgressInterval()
        if (videoRef.current) {
          videoRef.current.pause()
          videoRef.current.currentTime = 0
        }
      }
    })

    return () => {
      if (playerRef.current) {
        playerRef.current.unload()
      }
      URL.revokeObjectURL(audioUrl)
      clearProgressInterval()
    }
  }, [audioBlob])

  // Reset state when dialog is closed
  useEffect(() => {
    if (!isOpen) {
      setMergedVideoUrl(null)
      setUploadedMediaUrls({})
      setActiveIndex(0)
      setIsPlaying(false)
      setProgress(0)
      clearProgressInterval()
      // Clear file input refs when closing
      fileInputRefs.current = {}
    } else {
      // Update default media options when the dialog opens or skin changes
      // Keep custom uploads and always ensure the upload button is at the end

      // First gather any existing custom uploads
      const customUploads = mediaOptions.filter((option) => option.isCustomUpload === true && option.id !== 'upload')

      // Create a new unique ID for the upload button
      const uploadId = `upload-${uuidv4()}`

      const updatedOptions = [
        // Default skin video option
        {
          id: 'skin-video',
          type: 'video',
          title: 'Skin Video',
          previewUrl: skin.video,
          icon: <RiMovieFill size={24} />
        },
        // Default skin image option
        {
          id: 'skin-image',
          type: 'image',
          title: 'Skin Image',
          previewUrl: skin.image,
          icon: <FiFilm size={24} />
        },
        // Add any custom uploads the user has added
        ...customUploads,
        // Always add the upload button as the last option with a unique ID
        {
          id: uploadId,
          type: 'upload',
          title: 'Upload Media',
          icon: <RiImageAddLine size={24} />,
          isCustomUpload: true
        }
      ]

      setMediaOptions(updatedOptions as MediaOption[])
    }
  }, [isOpen, skin])

  // Detect iOS WebView on component mount
  useEffect(() => {
    // Check for iOS
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream

    // Check for WKWebView/UIWebView
    const webkitAvailable = Boolean(
      // @ts-ignore - webkit is not in the standard Window type
      window.webkit && window.webkit.messageHandlers
    )

    // Another way to detect WebView
    const userAgentHasWebView =
      /Version\/[\d.]+.*Safari/.test(navigator.userAgent) &&
      /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/.test(navigator.userAgent)

    setIsInIOSWebView(iOS && (webkitAvailable || userAgentHasWebView))
  }, [])

  // Handle video element sync with audio
  useEffect(() => {
    if (isPlaying) {
      if (videoRef.current) {
        videoRef.current.muted = true
        videoRef.current.play().catch((e) => console.error('Error playing video:', e))
      }
    } else {
      if (videoRef.current) {
        videoRef.current.pause()
      }
    }
  }, [isPlaying])

  const clearProgressInterval = () => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current)
      progressIntervalRef.current = null
    }
  }

  const updateProgress = () => {
    if (playerRef.current) {
      const duration = playerRef.current.duration()
      const seek = playerRef.current.seek()
      if (typeof seek === 'number') {
        setProgress((seek / duration) * 100)
      }
    }
  }

  const togglePlayback = () => {
    if (!playerRef.current) return

    if (isPlaying) {
      playerRef.current.pause()
      clearProgressInterval()
    } else {
      playerRef.current.play()
      progressIntervalRef.current = setInterval(updateProgress, 50)
    }

    setIsPlaying(!isPlaying)
  }

  const handleProgressBarClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!playerRef.current) return

    const progressBar = e.currentTarget
    const rect = progressBar.getBoundingClientRect()
    const clickPosition = (e.clientX - rect.left) / rect.width

    const duration = playerRef.current.duration()
    const newPosition = duration * clickPosition

    playerRef.current.seek(newPosition)
    setProgress(clickPosition * 100)

    if (videoRef.current) {
      const videoDuration = videoRef.current.duration
      // If video is shorter than audio, seek proportionally
      const videoPosition = (newPosition / duration) * videoDuration
      videoRef.current.currentTime = videoPosition
    }
  }

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>, optionId: string) => {
    const file = e.target.files?.[0]
    if (!file) return

    setIsUploading(true)

    try {
      // Upload the file
      const fileExt = file.name.split('.').pop() || ''
      const filename = `${title || 'media'}-${uuidv4()}.${fileExt}`
      const uploadFile = new File([file], filename, { type: file.type })

      const { url } = await upload(filename, uploadFile, {
        access: 'public',
        handleUploadUrl: '/api/blob/upload'
      })

      // Create a unique ID for the new media option
      const newMediaId = `media-${uuidv4()}`

      // Create a new media option for the uploaded file
      const newMediaOption: MediaOption = {
        id: newMediaId,
        type: file.type.startsWith('video/') ? 'video' : 'image',
        title: 'Your Upload',
        previewUrl: url,
        icon: file.type.startsWith('video/') ? <RiMovieFill size={24} /> : <FiFilm size={24} />,
        file: uploadFile,
        isCustomUpload: true
      }

      // Find the index of the current upload option (the one that triggered this upload)
      const currentUploadIndex = mediaOptions.findIndex((option) => option.id === optionId)

      // Generate a new unique ID for the next upload button
      const newUploadId = `upload-${uuidv4()}`

      // Create a new upload option to add after this one
      const newUploadOption: MediaOption = {
        id: newUploadId,
        type: 'upload',
        title: 'Upload Media',
        icon: <RiImageAddLine size={24} />,
        isCustomUpload: true
      }

      // Update the media options array
      const updatedOptions = [...mediaOptions]

      if (currentUploadIndex !== -1) {
        // Replace the current upload option with the uploaded media
        updatedOptions[currentUploadIndex] = newMediaOption

        // Add a new upload option at the end
        updatedOptions.push(newUploadOption)

        // Update the uploaded media URLs
        setUploadedMediaUrls((prev) => ({
          ...prev,
          [newMediaId]: url
        }))

        // Stay on the current index which now shows the uploaded media
        setActiveIndex(currentUploadIndex)
      } else {
        // Fallback: Just add to the end
        updatedOptions.push(newMediaOption, newUploadOption)
        setActiveIndex(updatedOptions.length - 2) // Point to the newly added media
      }

      setMediaOptions(updatedOptions as MediaOption[])

      toast(
        <div className="flex w-full justify-between">
          <span>Media uploaded successfully!</span>
          <FiCheckCircle className="text-green-500" />
        </div>
      )
    } catch (error) {
      console.error('Error uploading file:', error)
      toast(
        <div className="flex w-full justify-between">
          <span>Failed to upload media.</span>
        </div>
      )
    } finally {
      setIsUploading(false)
    }
  }

  const handleShare = async () => {
    if (!audioBlob) return

    setIsMerging(true)

    try {
      // First upload the audio file
      const audioFilename = `${title || 'recording'}-${uuidv4()}.wav`
      const audioFile = new File([audioBlob], audioFilename, { type: 'audio/wav' })

      const { url: audioUrl } = await upload(audioFilename, audioFile, {
        access: 'public',
        handleUploadUrl: '/api/blob/upload'
      })

      // Get the current media option
      const currentMedia = mediaOptions[activeIndex]

      // If it's an upload type (which means it hasn't been uploaded yet), show error
      if (currentMedia.type === 'upload') {
        toast(
          <div className="flex w-full justify-between">
            <span>Please upload a media file first</span>
          </div>
        )
        setIsMerging(false)
        return
      }

      // Get the media URL from the media option
      const mediaUrl = currentMedia.previewUrl

      if (!mediaUrl) {
        toast(
          <div className="flex w-full justify-between">
            <span>No media selected</span>
          </div>
        )
        setIsMerging(false)
        return
      }

      // Call the actual media merge service
      try {
        const response = await fetch('https://processor.justice.engineering/merge', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            input: [mediaUrl, audioUrl]
          })
        })

        if (!response.ok) {
          throw new Error(`Server responded with status: ${response.status}`)
        }

        const result = await response.json()
        console.log('merge response:', result)

        // Use the returned blob URL or download URL
        const mergedUrl = result.blobUrl || result.downloadUrl

        if (!mergedUrl) {
          throw new Error('No URL returned from merge service')
        }

        setMergedVideoUrl(mergedUrl)

        toast(
          <div className="flex w-full justify-between">
            <span>Media and audio merged successfully!</span>
            <FiCheckCircle className="text-green-500" />
          </div>
        )
      } catch (error) {
        console.error('Error calling merge service:', error)
        toast(
          <div className="flex w-full justify-between">
            <span>Failed to merge media: {(error as Error).message || 'Unknown error'}</span>
          </div>
        )
      } finally {
        setIsMerging(false)
      }
    } catch (error) {
      console.error('Error preparing for merge:', error)
      toast(
        <div className="flex w-full justify-between">
          <span>Failed to prepare files for merging.</span>
        </div>
      )
      setIsMerging(false)
    }
  }

  const handleKeep = async () => {
    if (!audioBlob) return

    setIsUploading(true)

    try {
      // Create a filename with a unique ID
      const filename = `${title || 'recording'}-${uuidv4()}.wav`

      // Convert Blob to File which is needed for the upload function
      const file = new File([audioBlob], filename, { type: 'audio/wav' })

      // Use the upload function which matches the working pattern in the app
      const { url } = await upload(filename, file, {
        access: 'public',
        handleUploadUrl: '/api/blob/upload'
      })

      copy(url)

      toast(
        <div className="flex w-full justify-between">
          <span>Audio URL copied to clipboard!</span>
          <FiCheckCircle className="text-green-500" />
        </div>
      )

      onClose()
    } catch (error) {
      console.error('Error uploading audio:', error)
      toast(
        <div className="flex w-full justify-between">
          <span>Failed to upload audio. Downloading instead...</span>
        </div>
      )

      // Fallback to direct download if upload fails
      if (audioBlob) {
        saveAs(audioBlob, `${title || 'recording'}.wav`)
      }
    } finally {
      setIsUploading(false)
    }
  }

  const triggerFileUpload = (id: string) => {
    const inputRef = getFileInputRef(id)
    inputRef?.click()
  }

  // Function to communicate with native iOS app
  const openNativeShareSheet = (url: string) => {
    // Method 1: Use a custom URL scheme that the iOS app can intercept
    // The iOS app should register a URL scheme like "slaps://"
    const encodedUrl = encodeURIComponent(url)
    const customUrlScheme = `slaps://share?url=${encodedUrl}&type=video&title=${encodeURIComponent(title || 'My Recording')}`

    // Create an invisible iframe to trigger the custom URL scheme
    const iframe = document.createElement('iframe')
    iframe.style.display = 'none'
    iframe.src = customUrlScheme
    document.body.appendChild(iframe)

    // Clean up the iframe after a short delay
    setTimeout(() => {
      document.body.removeChild(iframe)
    }, 100)

    // Method 2: Use window.webkit.messageHandlers for WKWebView
    try {
      // @ts-ignore - This is a special property available in WKWebView
      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.shareVideo) {
        // @ts-ignore
        window.webkit.messageHandlers.shareVideo.postMessage({
          url: url,
          title: title || 'My Recording'
        })
      }
    } catch (e) {
      console.error('Error sending message to iOS app:', e)
    }
  }

  const renderMediaPreview = () => {
    const currentMedia = mediaOptions[activeIndex]

    if (mergedVideoUrl) {
      return (
        <div className="relative flex h-52 items-center justify-center overflow-hidden rounded-lg bg-gray-900">
          <video ref={videoRef} src={mergedVideoUrl} className="h-full w-full object-cover" controls={false} loop />
          <div className="absolute inset-0 flex items-center justify-center">
            <button
              onClick={togglePlayback}
              className="rounded-full bg-primary/20 p-2 transition-colors hover:bg-primary/30"
            >
              {/* @ts-ignore - Components do accept size in the actual implementation */}
              {isPlaying ? <PauseIcon size={36} /> : <PlayIcon size={36} />}
            </button>
          </div>
        </div>
      )
    }

    if (currentMedia.type === 'upload') {
      // Get current media ID
      const mediaId = currentMedia.id

      return (
        <div
          className="flex h-52 cursor-pointer flex-col items-center justify-center rounded-lg bg-gray-900"
          onClick={() => triggerFileUpload(mediaId)}
        >
          <FiUpload size={48} className="mb-2 text-gray-400" />
          <p className="text-sm text-gray-400">Click to upload image or video</p>
          <input
            type="file"
            ref={(ref) => setFileInputRef(mediaId, ref)}
            onChange={(e) => handleFileUpload(e, mediaId)}
            accept="image/jpeg,image/png,image/gif,video/mp4,video/webm"
            className="hidden"
          />
        </div>
      )
    }

    if (currentMedia.type === 'video') {
      return (
        <div className="relative flex h-52 items-center justify-center overflow-hidden rounded-lg bg-gray-900">
          <video
            ref={videoRef}
            src={currentMedia.previewUrl || ''}
            className="h-full w-full object-cover"
            controls={false}
            loop
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <button
              onClick={togglePlayback}
              className="rounded-full bg-primary/20 p-2 transition-colors hover:bg-primary/30"
            >
              {/* @ts-ignore - Components do accept size in the actual implementation */}
              {isPlaying ? <PauseIcon size={36} /> : <PlayIcon size={36} />}
            </button>
          </div>
        </div>
      )
    }

    // Default to image display
    return (
      <div className="relative flex h-52 items-center justify-center overflow-hidden rounded-lg bg-gray-900">
        <img src={currentMedia.previewUrl || ''} alt={currentMedia.title} className="h-full w-full object-cover" />
        <div className="absolute inset-0 flex items-center justify-center">
          <button
            onClick={togglePlayback}
            className="rounded-full bg-primary/20 p-2 transition-colors hover:bg-primary/30"
          >
            {/* @ts-ignore - Components do accept size in the actual implementation */}
            {isPlaying ? <PauseIcon size={36} /> : <PlayIcon size={36} />}
          </button>
        </div>
      </div>
    )
  }

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="border-gray-800 bg-black text-white sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Share Your Recording</DialogTitle>
          <DialogDescription>Choose how you want to share your audio.</DialogDescription>
        </DialogHeader>

        <div className="flex flex-col space-y-4 py-4" {...handlers}>
          {renderMediaPreview()}

          <div className="h-4 w-full cursor-pointer rounded-full bg-gray-700" onClick={handleProgressBarClick}>
            <Progress value={progress} className="h-4" />
          </div>

          {/* Swipe indicator dots */}
          <div className="flex justify-center space-x-2">
            {mediaOptions.map((_, index) => (
              <button
                key={index}
                className={`h-2 w-2 rounded-full ${index === activeIndex ? 'bg-primary' : 'bg-gray-600'}`}
                onClick={() => setActiveIndex(index)}
              />
            ))}
          </div>

          <div className="flex items-center justify-center text-sm text-gray-400">
            <MdDragIndicator className="mr-1" /> Swipe to view options
          </div>
        </div>

        <DialogFooter className="flex gap-2 sm:gap-2">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>

          {mergedVideoUrl ? (
            <>
              <Button
                variant="secondary"
                onClick={() => {
                  if (mergedVideoUrl) {
                    const a = document.createElement('a')
                    a.href = mergedVideoUrl
                    a.download = `${title || 'recording'}-merged.mp4`
                    a.click()
                  }
                }}
              >
                Download
              </Button>
              <Button
                onClick={() => {
                  if (mergedVideoUrl) {
                    copy(mergedVideoUrl)
                    toast(
                      <div className="flex w-full justify-between">
                        <span>Video URL copied to clipboard!</span>
                        <FiCheckCircle className="text-green-500" />
                      </div>
                    )
                    onClose()
                  }
                }}
              >
                Copy Link
              </Button>
              <Button
                variant={isInIOSWebView ? 'default' : 'outline'}
                className={isInIOSWebView ? 'bg-green-600 font-bold text-white hover:bg-green-700' : ''}
                onClick={() => {
                  if (mergedVideoUrl) {
                    // Method 1: Try to use the native Web Share API if available
                    if (navigator.share) {
                      navigator
                        .share({
                          title: title || 'My Audio Recording',
                          text: 'Check out my audio recording!',
                          url: mergedVideoUrl
                        })
                        .catch((err) => {
                          console.log('Error sharing:', err)
                          // Fallback to method 2 if sharing fails
                          openNativeShareSheet(mergedVideoUrl)
                        })
                    } else {
                      // Method 2: Use a custom URL scheme for iOS WebView
                      openNativeShareSheet(mergedVideoUrl)
                    }
                  }
                }}
              >
                {isInIOSWebView ? 'Share to Instagram/TikTok' : 'Share'}
              </Button>
            </>
          ) : (
            <>
              <Button variant="secondary" onClick={handleKeep} disabled={isUploading || isMerging || !audioBlob}>
                {isUploading ? 'Uploading...' : 'Audio Only'}
              </Button>
              <Button onClick={handleShare} disabled={isUploading || isMerging || !audioBlob}>
                {isMerging ? 'Processing...' : 'Share with Video'}
              </Button>
            </>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default AudioPreviewDialog

import { FiRotateCcw } from 'react-icons/fi'

import { Slider } from '@/src/components/ui/slider'
import { Label } from '@/src/components/ui/label'
import { useTracks } from '../context/audio-provider'
import { useSkin } from '../context/skin-provider'
import { useLooper } from '../context/looper-provider'
import { useCallback } from 'react'

const BpmSlider = () => {
  const { bpm, currentBpm, setCurrentBpm } = useLooper()
  const { updateBpm } = useTracks()
  const { skin } = useSkin()

  const handleValueChange = useCallback(
    (value: number[]) => {
      setCurrentBpm(value[0])
    },
    [setCurrentBpm, updateBpm]
  )

  const resetBpm = useCallback(() => {
    setCurrentBpm(bpm)
  }, [bpm, setCurrentBpm, updateBpm])

  const sliderValue = currentBpm ? currentBpm : bpm
  return (
    <div className="flex w-full flex-col items-center gap-2 pl-5">
      <div className="flex w-full md:w-[60%]">
        <Label htmlFor="bpm" className="text-left">
          BPM <span>{sliderValue}</span>
        </Label>
      </div>
      <div className="mb-6 flex w-full items-center justify-between md:w-[60%] md:flex-row">
        <Slider
          title="Set BPM"
          value={[sliderValue]}
          max={300}
          min={1}
          step={1}
          onValueChange={handleValueChange}
          color={skin.bgColor}
        />
        <button title="Reset BPM" className="pl-2" onClick={resetBpm}>
          <FiRotateCcw />
        </button>
      </div>
    </div>
  )
}

export default BpmSlider

import React from 'react'
import { cn } from '../lib/utils'
import { useLooper } from '../context/looper-provider'

const KeyLocker = () => {
  const { key, currentKey, keyLocked, setKeyLocked } = useLooper()

  const icon = keyLocked ? '🔐' : '🔑'
  const buttonClass = `transition duration-150 ease-in-out
  border border-input px-2 md:ml-2 cursor-pointer text-sm
  disabled:bg-opacity-90 disabled:text-gray-400 disabled:cursor-not-allowed disabled:border-gray-400`

  return (
    <div className="flex w-full flex-col items-end">
      <div className="flex flex-col md:flex-row">
        <p className="p-0.5 text-left text-sm">
          {icon} {currentKey ? currentKey : key ? key : '--'}
        </p>
        <button
          title="Toggle Locked Key"
          disabled={!key}
          className={cn(buttonClass, {
            disabled: !key
          })}
          onClick={() => setKeyLocked(!keyLocked)}
        >
          {keyLocked ? 'Unlock' : 'Lock'}
        </button>
      </div>
    </div>
  )
}

export default KeyLocker

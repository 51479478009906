import React, { useRef, useCallback, useState, useImperativeHandle, forwardRef } from 'react'
import TableViewCell from './TableViewCell'
import SearchBar from './SearchBar'
import { playSound, getAudioBase64, Preview, parsePrettyTitle } from '../lib/utils'
import { useTracks } from '@/src/context/audio-provider'
import { Sample } from '@prisma/client'
import { useTrackQueue } from '../lib/trackQueueStore'
import { useLooper } from '../context/looper-provider'

interface SampleListProps {
  samples: Sample[]
  onSearch: (query: string) => void
  onAdd: (sample: Sample) => void
  loadMoreSamples: () => void
  hasMoreSamples: boolean
  isLoadingSamples: boolean
  isInitialLoading: boolean
  isSearching: boolean
}

export interface SampleListRef {
  stopAllAudio: () => void
  unloadAudio: () => void
}

const SampleList = forwardRef<SampleListRef, SampleListProps>(
  (
    { samples, onSearch, onAdd, loadMoreSamples, hasMoreSamples, isLoadingSamples, isInitialLoading, isSearching },
    ref
  ) => {
    const observer = useRef<IntersectionObserver | null>(null)
    const lastSampleElementRef = useCallback(
      (node: HTMLDivElement | null) => {
        if (isLoadingSamples) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && hasMoreSamples) {
            loadMoreSamples()
          }
        })
        if (node) observer.current.observe(node)
      },
      [isLoadingSamples, hasMoreSamples, loadMoreSamples]
    )

    const [previews, setPreviews] = useState<Preview[]>([])
    const { getPlayer, lockedDuration, tracks } = useTracks()
    const sessionTrackTitles = tracks.map((t) => t.title)
    const [playing, setPlaying] = useState<string | null>(null)
    const { bpm, currentBpm } = useLooper()
    const { items: queue } = useTrackQueue()
    const queueTrackTitle = queue.map((t) => t.title)

    const stopAllAudio = () => {
      previews.forEach((p) => {
        p.player.stop()
      })
    }

    useImperativeHandle(ref, () => ({
      stopAllAudio: () => {
        stopAllAudio()
        setPlaying(null)
      },
      unloadAudio: () => {
        previews.forEach((p) => {
          p.player.off('load')
          p.player.off('end')
          p.player.unload()
        })
        setPlaying(null)
      }
    }))

    const togglePlay = async (sample: Sample) => {
      const title = sample.title.split('###')[0]
      stopAllAudio()
      const existingPreview = previews.find((p) => p.src === sample.url)
      if (existingPreview) {
        if (playing === title) {
          setPlaying(null)
          return
        }
        playSound(existingPreview, title)
        setPlaying(title)
        return
      }
      const b64audio = await getAudioBase64(sample.url)
      if (!b64audio) throw new Error('Error: base64 audio string is corrupted.')
      const duration = lockedDuration ? lockedDuration : sample.duration
      const sourceBpm = bpm
      const targetBpm = currentBpm || bpm
      const preview = {
        // @ts-ignore
        player: getPlayer(b64audio, title, sourceBpm, targetBpm, duration * 1000, true),
        src: sample.url,
        startTime: 0,
        duration
      }
      setPreviews((prev) => [...prev, preview])
      preview.player.on('load', () => {
        playSound(preview, title)
      })
      setPlaying(title)
      preview.player.on('end', () => {
        setPlaying(null)
      })
    }

    return (
      <>
        <SearchBar onSearch={onSearch} placeholder="Search Samples" />
        <div className="flex-grow overflow-y-auto pb-20">
          {samples.length > 0 ? (
            samples.map((sample, index) => (
              <div key={sample.id} ref={index === samples.length - 1 ? lastSampleElementRef : null}>
                <TableViewCell
                  title={parsePrettyTitle(sample)}
                  subtitle={`Key: ${sample.key} | BPM: ${sample.bpm}`}
                  playing={playing}
                  showPlay={true}
                  onPlay={() => togglePlay(sample)}
                  showAdd={true}
                  onPress={() => {}}
                  onAdd={() => {
                    stopAllAudio()
                    setPlaying(null)
                    onAdd(sample)
                  }}
                  inSession={sessionTrackTitles.includes(parsePrettyTitle(sample))}
                  queued={queueTrackTitle.includes(parsePrettyTitle(sample))}
                />
              </div>
            ))
          ) : (
            <div className="p-4 text-center">
              {isInitialLoading || isLoadingSamples ? 'Loading samples...' : 'No search results'}
            </div>
          )}
          {isLoadingSamples && <div className="p-4 text-center">Loading more samples...</div>}
          {/* 7 samples render on mobile, we don't need to show a message here if there is space below, its redundant */}
          {!isSearching && !hasMoreSamples && samples.length > 7 && (
            <div className="p-4 text-center">No more samples to load</div>
          )}
        </div>
      </>
    )
  }
)

SampleList.displayName = 'SampleList'

export default SampleList

import React, { useCallback, useRef } from 'react'
import SearchBar from './SearchBar'
import TableViewCell from './TableViewCell'
import { GenreListItem } from '../types'

interface GenreListProps {
  genres: GenreListItem[]
  onGenreSelect: (genre: GenreListItem) => void
  onSearch: (query: string) => void
  loadMoreGenres: () => void
  hasMoreGenres: boolean
  isLoadingGenres: boolean
  isInitialLoading: boolean
  isSearching: boolean
}

const GenreList: React.FC<GenreListProps> = ({
  genres,
  onGenreSelect,
  onSearch,
  loadMoreGenres,
  hasMoreGenres,
  isLoadingGenres,
  isInitialLoading,
  isSearching
}) => {
  const observer = useRef<IntersectionObserver | null>(null)
  const lastGenreElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isLoadingGenres) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMoreGenres) {
            console.log('Genre intersection triggered') // Debug log
            loadMoreGenres()
          }
        },
        {
          root: null, // Use viewport
          threshold: 0.1 // Trigger when even 10% is visible
        }
      )
      if (node) observer.current.observe(node)
    },
    [isLoadingGenres, hasMoreGenres, loadMoreGenres]
  )

  return (
    <div className="flex h-full w-full flex-col">
      <SearchBar onSearch={onSearch} placeholder="Search Genres" />
      <div className="h-0 flex-grow overflow-y-auto pb-20">
        {genres.length > 0 ? (
          genres.map((genre, index) => (
            <div key={genre.name} ref={index === genres.length - 1 ? lastGenreElementRef : null} className="w-full">
              <TableViewCell
                title={genre.name}
                subtitle={`Drums: ${genre.drumCount} | Songstarters: ${genre.songstarterCount}`}
                onPress={() => onGenreSelect(genre)}
                showPlay={false}
                showAdd={false}
              />
            </div>
          ))
        ) : (
          <div className="p-4 text-center">
            {isInitialLoading || isLoadingGenres ? 'Loading samples...' : 'No search results'}
          </div>
        )}
        {isLoadingGenres && <p className="py-2 text-center">Checking for more genres...</p>}
        {!isSearching && !hasMoreGenres && genres.length > 0 && (
          <p className="py-2 text-center">No more genres to load</p>
        )}
      </div>
    </div>
  )
}

export default GenreList

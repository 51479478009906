import Modal from '@/src/components/Modal'
import { Dispatch, SetStateAction, useState } from 'react'
import { ActionItem, Sample } from '@/src/types'
import { Track, useTracks } from '@/src/context/audio-provider'
import { cn, downloadAsZip } from '@/src/lib/utils'
import { useSkin } from '@/src/context/skin-provider'
import {
  PiCopyThin,
  PiDownloadThin,
  PiFadersThin,
  PiHeartbeatThin,
  PiPianoKeysThin,
  PiShareThin,
  PiTrashThin
} from 'react-icons/pi'
import { useSearchParams } from 'next/navigation'
import { Spinner } from '@/src/components/Loader'
import { saveAs } from 'file-saver'
import { useMixpanelContext } from '@/src/lib/mixpanel/useMixpanel'
import { copyAndToast } from '@/src/components/Toast'

import BpmSlider from '@/src/components/BpmSlider'
import KeySelector from '@/src/components/KeySelector'
import FxControl from './FXControl'

const ActionModal = ({
  isOpen,
  setIsOpen,
  selectedAction,
  selectedTrack,
  handleClose,
  isLoading,
  setIsLoading,
  waitForTrackToEnd
}: {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  selectedAction: ActionItem
  selectedTrack: Track | null
  handleClose: () => void
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  waitForTrackToEnd: () => Promise<void>
}) => (
  <Modal
    setStatus={null}
    onClose={handleClose}
    className={cn('flex flex-col justify-start gap-6')}
    title={selectedAction?.description}
    isOpen={isOpen}
    setIsOpen={setIsOpen}
  >
    {!!selectedAction?.Component && (
      <selectedAction.Component
        track={selectedTrack}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setIsOpen={setIsOpen}
        waitForTrackToEnd={waitForTrackToEnd}
      />
    )}
  </Modal>
)

export const ActionMenu = ({
  deleteTrack,
  track,
  isLoading,
  setIsLoading,
  handleShare,
  addPresetLoop,
  unselectTrack,
  waitForTrackToEnd
}: {
  deleteTrack: (track: Track) => void
  track: Track | null
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  handleShare: () => void
  addPresetLoop: (sample: Sample, isRemixTrack?: boolean, newBpmAfterTrackAdded?: number) => void
  unselectTrack: (t: Track) => void
  waitForTrackToEnd: () => Promise<void>
}) => {
  const { skin } = useSkin()
  const { tracks } = useTracks()
  const mixpanel = useMixpanelContext()
  const [loading] = useState<string[]>([])
  const [selectedAction, setSelectedAction] = useState<ActionItem | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const searchParams = useSearchParams()
  const isEmbedded = searchParams.get('embedded') !== null

  const actions: ActionItem[] = [
    {
      id: 'download',
      name: 'Download',
      disabled: isEmbedded,
      description: 'Download the selected audio track',
      scopes: ['track'],
      Icon: PiDownloadThin,
      execute: async (t: Track | null) => {
        if (!t) return
        try {
          const response = await fetch(t.src)
          if (!response.ok) {
            throw new Error('Fetch of track url failed; Network response was not ok')
          }
          const arrayBuffer = await response.arrayBuffer()
          const file = new Blob([arrayBuffer], { type: 'audio/mpeg' })
          saveAs(file, t.title)
        } catch (error) {
          console.error('Fetch error:', error)
        }
        mixpanel.track({
          eventName: 'DownloadTrack',
          data: {
            duration: track?.data[0].duration,
            bpm: track?.data[0].bpm,
            key: track?.data[0].key,
            skin
          }
        })
      }
    },
    {
      id: 'copy-url',
      name: 'Copy URL',
      description: 'Copy the URL of the selected audio track',
      scopes: ['track'],
      Icon: PiCopyThin,
      execute: (t: Track | null) => {
        if (!t) return
        console.log('clicked')
        copyAndToast(t.src)
        mixpanel.track({
          eventName: 'CopyURL',
          data: {
            duration: track?.data[0].duration,
            bpm: track?.data[0].bpm,
            key: track?.data[0].key,
            skin
          }
        })
      }
    },
    {
      id: 'delete',
      name: 'Delete',
      description: 'Delete the selected audio track',
      scopes: ['track'],
      Icon: PiTrashThin,
      execute: (t: Track | null) => {
        if (!t) return
        unselectTrack(t)
        deleteTrack(t)
        mixpanel.track({
          eventName: 'DeleteTrack',
          data: {
            duration: track?.data[0].duration,
            bpm: track?.data[0].bpm,
            key: track?.data[0].key,
            skin
          }
        })
      }
    },
    // global actions
    {
      id: 'update-bpm',
      name: 'BPM',
      description: 'Update the BPM of the global audio',
      scopes: ['global'],
      Icon: PiHeartbeatThin,
      execute: undefined,
      Component: BpmSlider
    },
    {
      id: 'update-key',
      name: 'Key',
      disabled: isEmbedded,
      description: 'Update the Key of the global audio',
      scopes: ['global'],
      Icon: PiPianoKeysThin,
      execute: undefined,
      Component: KeySelector
    },
    {
      id: 'share',
      name: 'Share',
      description: 'Share a link to the slap',
      scopes: ['global'],
      Icon: PiShareThin,
      execute: handleShare
    },
    {
      id: 'download-all',
      name: 'Download',
      disabled: isEmbedded,
      description: 'Download all audio tracks',
      scopes: ['global'],
      Icon: PiDownloadThin,
      execute: async () => {
        if (!tracks.length) return
        const mappedTracks = await Promise.all(
          tracks.map(async (track) => {
            const data = await fetch(track.src).then((response) => response.blob())
            return {
              name: track.title,
              data
            }
          })
        )
        const filteredTracks = mappedTracks.filter((track) => !!track?.data) || []
        downloadAsZip(filteredTracks, 'tracks')
      }
    },
    {
      id: 'fx',
      name: 'FX',
      description: 'Remix your session to match a genre',
      scopes: ['global'],
      Icon: PiFadersThin,
      Component: () => <FxControl addPresetLoop={addPresetLoop} />
    }
  ]

  const scopedActions = actions.filter((action) => action.scopes.includes(track ? 'track' : 'global'))
  return (
    <div className="z-50 flex h-full w-full items-center justify-center">
      <div
        className="flex items-center justify-center gap-10 rounded p-5 backdrop-blur-xl"
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0)'
        }}
      >
        {scopedActions.map((action) => {
          const isLoading = loading.some((l) => l === `${action.id}-${track?.id}`)
          const execute = action.execute
          const isDisabled = action.disabled

          const onClick = execute
            ? () => execute(track)
            : () => {
                setSelectedAction(action)
                setIsOpen(true)
              }

          const textColorClass = cn({ 'text-gray-400': action.disabled, 'text-white': !action.disabled })
          return (
            <button
              key={action.id}
              title={action.name}
              onClick={isDisabled ? () => {} : onClick}
              className={`flex w-fit flex-col items-center gap-3 ${textColorClass}`}
              style={{
                color: skin.textColor,
                borderColor: skin.textColor
              }}
            >
              {isLoading ? <Spinner className="h-6 w-6" /> : <action.Icon size={24} className={textColorClass} />}
              <span className={`text-[8px] uppercase tracking-widest ${textColorClass}`}>{action.name}</span>
            </button>
          )
        })}

        {isOpen && selectedAction && (
          <ActionModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectedAction={selectedAction}
            selectedTrack={track}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            handleClose={() => {
              setIsOpen(false)
            }}
            waitForTrackToEnd={waitForTrackToEnd}
          />
        )}
      </div>
    </div>
  )
}
